// Here you can add other styles
.customIcon {
  fill: white;
  width: 14px;
  height: 14px;
}

.customRupeeIcon {
  fill: rgb(60, 75, 100);
  width: 14px;
  height: 10px;
}
.btn{
  border-radius: 0.25rem!important;
}
.loader-container {
  position: fixed !important;
  z-index: 1090;
  // height: 100%;
  // width: 100%;
  top: 50%;
  left: 50%;
}
.inventory-request-pagination .pagination {
  justify-content: end!important;
}
.page-loader {
  width: 4rem;
  height: 4rem;
  // margin-left: 32%;
  // margin-top: 10%;
  // z-index:1060;
}

.remove-index {
  z-index: -1;
}

.card.active {
  box-shadow: 0px 0px 5px 0px grey;
}

.card-body-custom-height {
  height: 100px !important;
}

.customPlusIcon {
  fill: white;
  width: 18px;
  height: 18px;
}

.customBookPatientIcon {
  fill: rgb(255, 255, 255);
  width: 20px;
  height: 20px;
}

.customMinusIcon {
  fill: black;
  width: 22px;
  height: 22px;
}

.customCalendarIcon {
  // fill: black;
  width: 30px;
  height: 20px;
  cursor: pointer !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.toaster-top-full,
.toaster-top-center,
.toaster-top-right,
.toaster-top-left,
.toaster-bottom-full,
.toaster-bottom-center,
.toaster-bottom-right,
.toaster-bottom-left {
  width: 40% !important;
}

.input-container input {
  border: none;
  box-sizing: border-box;
  outline: 0;
  padding: 0.75rem;
  position: relative;
  width: 100%;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

.bg-cover {
  margin: 0;
  padding: 0;
  background: url("./../assets/img/login_bg.jpeg") !important;
  background-size: cover;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  // top: 0;
  // left: 0;
  // bottom:0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 0;
  opacity: 0.9;
}

.box {
  padding: 1rem;
  background: rgba(0, 0, 0, 0.53);
  border-radius: 0.625rem;
  border-color: #27262300 !important;
}

.login-header {
  color: #fff;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
